<template>
  <div>
    <vx-card title="Status Unit" :is-refresh="isLoading">

      <ProyekList class="" @proyekRefreshed="params.id_proyek = $event; getData();" />

      <div class="p-6 pb-0">
        <div class="sm:flex">
          <div class="mr-6" v-for="legend in chartData.legends" :key="legend.key">
            <p class="mb-1 font-semibold">{{ legend.alias }}</p>
            <p class="text-3xl" :class="{'text-success': legend.value > 0}"><sup class="text-base mr-1"></sup>{{ legend.value }}</p>
          </div>
        </div>
        <vue-apex-charts
          type="line"
          height="266"
          :options="chartOptions"
          :series="chartData.series" />
      </div>
    </vx-card>
  </div>
</template>

<script>
import ReportRepository from '@/repositories/reports/report-repository'
import VueApexCharts from 'vue-apexcharts'
import ProyekList from '@/views/pages/dashboard/ProyekList'
import { abbreviateNumber } from '@/utilities/common/global-methods'

export default {
  name: 'StatusUnitLineChart',
  components: {
    VueApexCharts,
    ProyekList
  },
  mounted () {
    this.initData()
  },
  data () {
    return {
      isLoading: false,
      params: {
        id_proyek: null
      },
      data: {
        queryResult: []
      },
      chartData: {
        legends: [],
        categories: [],
        series: []
      },
      chartOptions: {
        chart: {
          toolbar: { show: false },
          dropShadow: {
            enabled: true,
            top: 5,
            left: 0,
            blur: 4,
            opacity: 0.10
          }
        },
        grid: {
          borderColor: '#e7e7e7'
        },
        legend: {
          show: true
        },
        colors: ['#c41ed0', '#3e71ee', '#e1de4c', '#EA5455', '#54ea7f'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['rgba(196,30,208,0.79)', 'rgba(65,111,238,0.82)', 'rgba(255,229,87,0.8)', 'rgba(234,84,85,0.81)', 'rgba(84,234,127,0.78)'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          }
        },
        markers: {
          size: 0,
          hover: {
            size: 5
          }
        },
        xaxis: {
          type: 'category',
          categories: ['Previous Year', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          labels: {
            style: {
              cssClass: 'text-grey fill-current'
            }
          },
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              cssClass: 'text-grey fill-current'
            },
            formatter (val) {
              return val > 999 ? abbreviateNumber(val.toFixed()) : val
            }
          }
        },
        tooltip: {
          x: { show: false }
        }
      }
    }
  },
  methods: {
    initData () {
      this.getData()
    },

    getData () {
      this.isLoading = true

      const reportId = 102
      const params = { params: this.params }
      return ReportRepository.templateData(reportId, params)
        .then(response => {
          // this.data = response.data
          this.chartData = response.data.chartData
          this.chartOptions.xaxis.categories = response.data.chartData.categories
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
