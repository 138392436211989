<template>
  <vs-select class="vs-select-small" :value="null" v-model="id_proyek" @input="onChangeProyek">
    <vs-select-item class="vs-select-small" text="Semua Proyek"/>
    <vs-select-item class="vs-select-small" v-for="(it, index) in listProyek" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
  </vs-select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProyekList',
  computed: {
    ...mapState({
      listProyek: state => state.dashboard.listProyek
    })
  },
  data () {
    return {
      id_proyek: null
    }
  },
  methods: {
    onChangeProyek (idProyek) {
      this.emitProyekRefreshed(idProyek)
    },
    emitProyekRefreshed (idProyek) {
      this.$emit('proyekRefreshed', idProyek)
    }
  }
}
</script>
